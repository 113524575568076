<template>
  <div class="mt-10 xl:mx-56 lg:mx-28 sm:mx-10 mx-4">
    <div class ="flex justify-between align-middle bg-white rounded-t px-8 pt-8 dark:bg-primaryDark">
      <div class="flex flex-row">
        <router-link title="Switch to Places" :to="{ name: 'Places'}">
          <div class="flex flex-row text-gray-400 hover:text-gray-700 dark:text-gray-500 dark-hover:text-gray-300 pl-2 mr-2">
            <div class="flex items-center justify-center">
              <font-awesome-icon icon="fa-solid fa-building" size="lg"/>
            </div>
            <h2 class="m-2 sm:text-xl text-lg self-center">Places</h2>
          </div>
        </router-link>
        <div class="flex flex-row dark:text-white">
          <div class="flex items-center justify-center">
            <font-awesome-icon icon="fa-solid fa-location-dot" size="lg"/>
          </div>
          <h2 class="m-2 sm:text-xl text-lg self-center">Spaces</h2>
        </div>
      </div>
      <router-link :to="{ name: 'AddSpace'}" v-if="store.getters.isLoggedIn">
        <AddButton title="Add Space">
          <font-awesome-icon icon="fa-solid fa-plus"/>
        </AddButton>
      </router-link>
      <router-link :to="{ name: 'AddStorage'}" v-if="store.getters.isLoggedIn">
        <AddButton title="Add Storage">
          <font-awesome-icon icon="fa-solid fa-plus"/>
        </AddButton>
      </router-link>
    </div>
    <DynamicTable :filters="spaceFilters" :link="profileViewLink" @sort="onSortParamEntry"  @next="onNextPage" @prev="onPreviousPage" :searchFunc="onSearchParamEntry" :update="update" />
  </div>
</template>


<script>
import {useStore} from "vuex";
import { computed } from "vue";
import { useRouter } from "vue-router";
import DynamicTable from "@/components/widgets/dynamicTable/DynamicTable";
import AddButton from "../components/inputs/AddButton.vue";
export default {
  name: "Spaces",
  components: { DynamicTable, AddButton },
  setup() {
    const store = useStore()
    const router = useRouter()

    store.dispatch('dynamicTableLoadSpaces')

    function onSpaceView(id){
      router.push({ name: 'Space', params: { id }})
    }

    return {
      onSpaceView,
      store,
      spaceFilters: computed(() => store.getters.getSpaceFilters),
      profileViewLink: computed(() => {
        return {func: onSpaceView, fieldName: 'Name'}
      }),
      async onSearchParamEntry(searchString) {
        store.commit('setDynamicTableSearchParam', searchString)
        await store.dispatch('dynamicTableLoadSpaces')
      },
      async update(){
        await store.dispatch('dynamicTableLoadSpaces')
      },
      async onSortParamEntry(key) {
        if (key === 'Name'){
          store.commit('setSpaceSortBy', 'name')
        } else if (key === 'maxCapacity') {
          store.commit('setSpaceSortBy', 'max_capacity')
        } else if (key === 'Venue') {
          store.commit('setSpaceSortBy', 'venue')
        } else if (key === 'spaceType') {
          store.commit('setSpaceSortBy', 'space_type')
        }
        await store.dispatch('dynamicTableLoadSpaces')
      },
      onNextPage: () => store.dispatch('spacesPageNext'),
      onPreviousPage: () => store.dispatch('spacesPagePrevious')
    }
  }
}
</script>

<style scoped>

</style>
