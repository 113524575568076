<template>
  <div class="flex sm:h-10 h-10 align-middle">
    <button type="button" class="flex flex-row items-center px-1 py-1 w-full shadow-sm rounded-md sm:text-sm text-base border border-gray-500 bg-primary text-gray-500 hover:text-gray-900 dark-hover:text-gray-200 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1">
      <div class="w-6 h-auto">
        <slot></slot>
      </div>
      <div class="hidden sm:block sm:mx-1">
        {{ title }}
      </div>
        <!-- <div class="px-0.5">
        </div> -->
    </button>
  </div>
</template>

<script>

export default {
  name: "AddButton",
  props: {
    title: String,
  },
}
</script>